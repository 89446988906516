import React from "react"
import parse from "html-react-parser"
import Img from "gatsby-image"
import styles from "./BlockTitle.module.scss"

const BlockTitle = ({ data }) => {
  let classAnimation = []
  let classChildren = []
  classAnimation.push(styles.blockTitle)
  classAnimation.push(styles.containerMedium)
  classAnimation.push(styles.defaultVerticalSpace)

  if (data.animazione !== false && data.animazione !== "false") {
    classAnimation.push("customScrollAnimation")
    classAnimation.push(data.animazione)
    if (data.animazione.includes("Children")) {
      classChildren.push("scrollChildren")
    }
  }

  if (data.titolo_principale === null || data.titolo_principale === false) {
    classChildren.push(styles.innerContainer)
  } else {
    classAnimation.push(styles.titleFullscreen)
  }

  const immagine = data.immagine
  return (
    <article className={classAnimation.join(" ")}>
      {immagine ? (
        <picture>
          <img className={styles.imgHomeMobile} src={"/imageHomeMobile.png"} />
          <Img
            className={classChildren.join(" ") + " " + styles.img}
            fluid={immagine.localFile.childImageSharp.fluid}
            alt={immagine.alt_text}
            data-delay={0}
            fadeIn={true}
          />
        </picture>
      ) : null}
      {data.titolo_principale === null ? (
        <h2
          className={classChildren.join(" ") + " " + styles.titolo}
          data-delay={200}
        >
          {parse(data.titolo)}
        </h2>
      ) : (
        <h1
          className={classChildren.join(" ") + " " + styles.titolo}
          data-delay={200}
        >
          {parse(data.titolo)}
        </h1>
      )}

      {data.sottotitolo ? (
        <h2
          className={classChildren.join(" ") + " " + styles.sottotitolo}
          data-delay={400}
        >
          {parse(data.sottotitolo)}
        </h2>
      ) : null}
      {data.testo ? (
        <p className={classChildren.join(" ")} data-delay={600}>
          {parse(data.testo)}
        </p>
      ) : null}
    </article>
  )
}

export default BlockTitle
