import React from "react"
import parse from "html-react-parser"
import Img from "gatsby-image"
import styles from "./BlockListaLoghi.module.scss"

const BlockListaLoghi = ({ data }) => {
  let classAnimation = []
  let classChildren = []
  classAnimation.push(styles.listaLoghi)
  classAnimation.push(styles.defaultVerticalSpace)
  classChildren.push(styles.logo)

  if (data.animazione !== false && data.animazione !== "false") {
    classAnimation.push("customScrollAnimation")
    classAnimation.push(data.animazione)
    if (data.animazione.includes("Children")) {
      classChildren.push("scrollChildren")
    }
  }

  const loghi = data.loghi.map((item, index) => {
    return (
      <div className={classChildren.join(" ")} key={index}>
        <Img
          fluid={item.logo.localFile.childImageSharp.fluid}
          alt={item.alt_text}
        />
      </div>
    )
  })
  return (
    <article className={classAnimation.join(" ")}>
      <div className={styles.containerMedium}>
        {data.titolo ? (
          <div className={styles.testo}>
            <h2>{parse(data.titolo)}</h2>
          </div>
        ) : null}
        <div className={styles.loghiContainer}>{loghi}</div>
      </div>
    </article>
  )
}

export default BlockListaLoghi
