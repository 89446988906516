import React from "react"
//import styles from "./BlocksElements.module.scss"
import BlockTitle from "./BlockTitle/BlockTitle"
import BlockListaLoghi from "./BlockListaLoghi/BlockListaLoghi"
import BlockListaFeatures from "./BlockListaFeatures/BlockListaFeatures"
import BlockImg from "./BlockImg/BlockImg"
import BlockTestimonial from "./BlockTestimonial/BlockTestimonial"
import BlockTesto from "./BlockTesto/BlockTesto"

const BlocksElements = ({ data, styledContainer }) => {
  const layout = data.map((item, index) => {
    switch (item.__typename) {
      case "WordPressAcf_blocco_titolo":
        return <BlockTitle data={item} key={index} />
      case "WordPressAcf_lista_loghi":
        return <BlockListaLoghi data={item} key={index} />
      case "WordPressAcf_lista_features":
        return <BlockListaFeatures data={item} key={index} />
      case "WordPressAcf_immagine":
        return <BlockImg data={item} key={index} />
      case "WordPressAcf_campo_di_testo":
        return <BlockTesto data={item} key={index} />
      case "WordPressAcf_testimonial":
        return <BlockTestimonial data={item} key={index} />
      default:
        return null
    }
  })
  return <div className={styledContainer}>{layout}</div>
}

export default BlocksElements
