import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ProjectList from "../components/ProjectList/ProjectList"
import styles from "./index.module.scss"
import BlocksElements from "../components/BlocksElement/BlocksElements"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

const IndexPage = ({ data, transitionStatus }) => {
  let transitionClass = "transitionContents " + transitionStatus

  const blocksElement = data.wordpressPage.acf.layout_page
  const meta = data.wordpressPage.yoast
  return (
    <TransitionBehavior>
      <div className={transitionClass}>
        <Header />
        <SEO meta={meta} />
        <BlocksElements data={blocksElement} />
        <div className={styles.containerMedium}>
          <ProjectList
            testimonial={true}
            immagine={true}
            projectArray={data.projects.edges}
          />
        </div>
        <Footer />
      </div>
    </TransitionBehavior>
  )
}

export default IndexPage

export const query = graphql`
  {
    wordpressPage(title: { eq: "Home" }) {
      yoast {
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        metadesc
        metakeywords
        opengraph_description
        opengraph_image
        opengraph_title
        redirect
        title
        twitter_description
        twitter_image
        twitter_title
      }
      acf {
        layout_page {
          ... on WordPressAcf_blocco_titolo {
            id
            sottotitolo
            testo
            titolo
            animazione
            titolo_principale
            immagine {
              alt_text
              localFile {
                childImageSharp {
                  fluid {
                    aspectRatio

                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
          ... on WordPressAcf_lista_features {
            id
            animazione
            features {
              descrizione
              titolo
              icona {
                localFile {
                  childImageSharp {
                    fluid {
                      aspectRatio
                      src
                      srcSet
                      sizes
                    }
                  }
                }
                alt_text
              }
            }
          }
          ... on WordPressAcf_lista_loghi {
            id
            titolo
            animazione
            loghi {
              logo {
                alt_text
                localFile {
                  childImageSharp {
                    fluid {
                      aspectRatio

                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    projects: allWordpressWpProgetto(
      filter: { acf: { featured: { eq: true } } }
    ) {
      edges {
        node {
          id
          slug
          acf {
            titolo
            sottotitolo
            header {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1980, quality: 10) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            layout_progetto {
              ... on WordPressAcf_testimonial {
                testimonial {
                  autore
                  autore_sottotitolo
                  home
                  quote
                  immagine {
                    source_url
                    localFile {
                      childImageSharp {
                        fluid {
                          src
                          srcSet
                          aspectRatio
                          sizes
                        }
                      }
                    }
                  }
                  __typename
                }
              }
            }
            featured
          }
        }
      }
    }
  }
`
