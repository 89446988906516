import React from "react"
import Img from "gatsby-image"
import styles from "./BlockImg.module.scss"

const BlockImg = ({ data, index }) => {
  let classAnimation = []
  let classChildren = []

  if (data.fullscreen === null || data.fullscreen === false) {
    classAnimation.push(styles.containerMedium)
  } else {
    classAnimation.push(styles.fullscreenimage)
  }
  classAnimation.push(styles.defaultVerticalSpace)

  if (data.animazione !== false && data.animazione !== "false") {
    classAnimation.push("customScrollAnimation")
    classAnimation.push(data.animazione)
    if (data.animazione.includes("Children")) {
      classChildren.push("scrollChildren")
    }
  }

  return (
    <div key={index} className={classAnimation.join(" ")}>
      <Img
        fluid={data.immagine.localFile.childImageSharp.fluid}
        alt={data.alt_text}
        className={classChildren.join(" ")}
      />
    </div>
  )
}
export default BlockImg
