import React from "react"
import styles from "./BlockTesto.module.scss"
import parse from "html-react-parser"

const BlockTesto = ({ data, index }) => {
  let classAnimation = []
  let classChildren = []
  classAnimation.push(styles.containerMedium)
  classAnimation.push(styles.defaultVerticalSpace)
  classAnimation.push(styles.blockTesto)
  classChildren.push(styles.column6)

  if (data.animazione !== false && data.animazione !== "false") {
    classAnimation.push("customScrollAnimation")
    classAnimation.push(data.animazione)
    if (data.animazione.includes("Children")) {
      classChildren.push("scrollChildren")
    }
  }

  return (
    <div key={index} className={classAnimation.join(" ")}>
      <div className={classChildren.join(" ")}>
        {parse(data.campo_di_testo)}
      </div>
    </div>
  )
}
export default BlockTesto
