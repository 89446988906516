import React from "react"
import parse from "html-react-parser"
import Img from "gatsby-image"
import styles from "./BlockListaFeatures.module.scss"

const BlockListaFeatures = ({ data }) => {
  let classAnimation = []
  let classChildren = []
  classAnimation.push(styles.feature)
  classAnimation.push(styles.containerMedium)
  classAnimation.push(styles.defaultVerticalSpace)
  classAnimation.push(styles.doubleVerticalSpaceBottom)
  classChildren.push(styles.wrapperFeature)

  if (data.animazione !== false && data.animazione !== "false") {
    classAnimation.push("customScrollAnimation")
    classAnimation.push(data.animazione)
    if (data.animazione.includes("Children")) {
      classChildren.push("scrollChildren")
    }
  }

  const feature = data.features.map((item, index) => {
    return (
      <div
        className={classChildren.join(" ")}
        key={index}
        data-delay={index * 200}
      >
        {item.icona !== null ? (
          <Img
            fluid={item.icona.localFile.childImageSharp.fluid}
            className={styles.icon}
            alt={item.alt_text}
          />
        ) : null}
        <h2>{parse(item.titolo.toUpperCase())}</h2>
        <h3>{parse(item.descrizione)}</h3>
      </div>
    )
  })
  return <article className={classAnimation.join(" ")}>{feature}</article>
}

export default BlockListaFeatures
